<template>
  <div id="editor">

      <aside v-if="isAdmin || isEducatore" class="sidebar">

          <ul  v-if="isAdmin">
            <li>
              <router-link :to="{ name: 'EditorDashboard'}">Dashboard</router-link>
            </li>
            <li>
               <router-link :to="{ name: 'EditorIstituti'}">Istituti comprensivi</router-link>
            </li>
            <li>
               <router-link :to="{ name: 'EditorScuole'}">Scuole</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'EditorClassi'}">Classi</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'EditorUtenti'}">Utenti</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'EditorPercorsi'}">Percorsi</router-link>
            </li>
             <li>
              <router-link :to="{ name: 'EditorLezioniGenerali'}">Lezioni generali</router-link>
            </li>
          </ul>

          <ul  v-if="isEducatore">
            <router-link :to="{ name: 'EditorDashboard'} ">Editor Percorso</router-link>
          </ul>

      </aside>

      <main>
        <router-view/>
      </main>

  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapState, mapActions } from 'vuex'
export default {
  name: 'EditorDashboard',

  data: () => {
    return {
     //classi: []
    }
  },
  
  components: {
    
  },

  computed: {
    ...mapGetters([
      'isLogged',
      'isAdmin',
      'isEducatore',
      'isInsegnante',
      'isClasse',
      'user'
    ]),

    ...mapGetters('classi',[
        'getClassi'
     ]),

    currentRouteName() {
        return this.$route.name;
    }

  },

  methods: {
    ...mapActions('classi',[
        'fetchClassi',
    ]),
  },

  beforeCreate() {
    this.$nextTick().then(() => document.body.className = '')
  },

  beforeMount() {
    this.$store.dispatch('classi/fetchClassi')
  },

  mounted() {

  }

}
</script>
